import * as React from "react"

const SvgInteriorPageDecorativeElement = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={369}
    height={18}
    viewBox="0 0 369 18"
    {...props}
  >
    <g data-name="Rectangle 10" fill="none" stroke="#020202" strokeWidth={2}>
      <path stroke="none" d="M0 0h18v18H0z" />
      <path d="M1 1h16v16H1z" />
    </g>
    <path data-name="Rectangle 12" d="M16 8h353v2H16z" />
    <path data-name="Rectangle 11" fill="#e32223" d="M12 4h10v10H12z" />
  </svg>
)

export default SvgInteriorPageDecorativeElement
