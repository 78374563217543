import React, { useState, useEffect, useMemo } from "react"
import * as maintenanceContractFormStyles from "./maintenance-contract-form.modules.css"

const MY_SITE_KEY = "6LehBf8kAAAAADMl5VKp9ImCrNtdKLQfGGrHemmm"

const MaintenanceContractForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    if (submitting) return // Prevent double form submission

    setSubmitting(true)

    const formData = new FormData(event.target)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(response => {
        // Check if the form submission triggered a redirect to the thank you page
        if (response.ok) {
          console.log("Form submitted successfully")
          setFormSubmitted(true)
        } else {
          // Form submission failed
          console.log(`Form submission failed: ${response}`)
        }
      })
      .catch(error => {
        console.error("An error occurred during form submission:", error)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  if (formSubmitted) {
    return <div>Thank you for your submission!</div>
  }

  const isButtonDisabled = submitting

  return (
    <div className={`maintenanceContractForm ${maintenanceContractFormStyles}`}>
      <form
        name="MaintenanceContractForm-form"
        method="POST"
        // eslint-disable-next-line react/no-unknown-property
        netlify-honeypot="bot-field"
        data-netlify="true"
        onSubmit={handleSubmit}
      >
        <input
          type="hidden"
          name="form-name"
          value="MaintenanceContractForm-form"
        />
        <label htmlFor="bot-field" style={{ display: "none" }}>
          Don’t fill this out if you&apos;re human:{" "}
          <input type="hidden" name="bot-field" id="bot-field" />
        </label>
        <input
          type="hidden"
          name="subject"
          value="Maintenance Contract form submission"
        />
        <input type="text" name="name" placeholder="Name" required />
        <input type="tel" name="phoneNumber" placeholder="Phone #" required />
        <p>Billing Address</p>
        <input name="billingStreetAddress" placeholder="Street Address" />
        <div className="cityStateZip">
          <input name="billingCity" placeholder="City" />
          <select name="billingState">
            <option value="">Select State</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
          <input name="billingZipCode" placeholder="Zip Code" />
        </div>

        <p>Location Address of Units</p>
        <input name="unitStreetAddress" required placeholder="Street Address" />
        <div className="cityStateZip">
          <input name="unitCity" required placeholder="City" />
          <select name="unitState" required>
            <option value="">Select State</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
          <input name="unitZipCode" required placeholder="Zip Code" />
        </div>
        <input
          name="numberOfHvacUnits"
          required
          placeholder="Number of HVAC Units"
          type="number"
        />

        <button
          style={{
            backgroundColor: "#D52924",
            color: "#fff",
            padding: "10px 30px",
            margin: "20px 0",
            WebkitAppearance: "none",
            cursor: isButtonDisabled ? "not-allowed" : "pointer",
          }}
          type="submit"
          disabled={isButtonDisabled}
        >
          {submitting ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  )
}

export { MaintenanceContractForm }
