import React, { useState } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "../Elements/Button"
import ReactPlayer from "react-player"
import Modal from "react-modal"
import {
  VideoSection,
  VideoSectionPlayer,
  VideoSectionContent,
  VideoSectionCard,
  PlayButton,
  VideoMask,
  CloseButton,
} from "./index.style"

const InteriorVideo = ({ data }) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)

  return (
    <>
      <VideoSection>
        <VideoSectionPlayer>
          <ReactPlayer
            url={data.backgroundVideoUrl}
            loop
            playing
            playsinline
            muted
            controls={false}
            className="react-player"
          />
        </VideoSectionPlayer>
        {data.videoBackgroundImage && (
          <VideoMask>
            <GatsbyImage
              image={data.videoBackgroundImage}
              alt={data.altText}
              className="background-image"
            />
          </VideoMask>
        )}
        <VideoSectionContent>
          <PlayButton
            dangerouslySetInnerHTML={{ __html: data.playButton }}
            onClick={() => setIsVideoModalOpen(true)}
          />
          <VideoSectionCard
            dangerouslySetInnerHTML={{ __html: data.cardContent }}
          />
        </VideoSectionContent>
      </VideoSection>
      <Modal
        isOpen={isVideoModalOpen}
        onRequestClose={() => {
          setIsVideoModalOpen(false)
        }}
        contentLabel="Example Modal"
        style={{
          overlay: {
            zIndex: 99,
          },
          content: {
            inset: "24px",
          },
        }}
      >
        <CloseButton onClick={() => setIsVideoModalOpen(false)}>x</CloseButton>
        <ReactPlayer
          url={data.fullVideoUrl}
          width="100%"
          height="100%"
          playing
          playsinline
          controls={true}
        />
      </Modal>
    </>
  )
}

export { InteriorVideo }
