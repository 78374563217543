import React from "react"
import styled from "@emotion/styled/macro"
import { StaticImage } from "gatsby-plugin-image"
import SvgCouponTop from "../images/SVGs/CouponTop"
import SvgCouponBottom from "../images/SVGs/CouponBottom"
import { theme } from "./Theme"

const Coupon = props => {
  const CouponContainer = styled.div`
    text-align: center;
    position: relative;
    margin-top: 50px;
    min-width: 75%;
    display: grid;
    grid-template-rows: 1fr 1fr;

    .gatsby-image-wrapper {
      margin: 0 auto;
    }
    svg {
      height: 100%;
    }
    .svgCouponTopContainer.gray {
      display: flex;
      align-items: flex-end;
      grid-area: 1/1;
      margin: 0 auto;
      & path {
        fill: #f1f1f1;
      }
    }
    .couponLogo {
      position: absolute;
      left: 50%;
      top: -61.5px;
      transform: translateX(-50%);
    }
    .svgCouponContainer {
      display: grid;
    }
    svg {
      grid-area: 1/1;
    }
    .couponText {
      grid-area: 1/1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .couponText.top {
      margin-top: 3rem;

      strong {
        text-transform: uppercase;
        font-weight: 900;
        font-family: ${theme.fonts.altFont};
        font-size: clamp(1rem, 0.8938rem + 0.4531vw, 1.4375rem);
        letter-spacing: 1;
      }
      div {
        margin: 0 1rem;
      }
    }
    .discount {
      padding: 5px 45px;
      border-radius: 50px;
      background-color: ${theme.colors.light};
      border: 1px dashed ${theme.colors.dark};
      font-size: 2.875rem;
      display: flex;
      align-items: center;
    }
    .amount {
      font-weight: 800;
    }
    .off {
      text-transform: uppercase;
      font-size: clamp(0.625rem, 0.3823rem + 1.0356vw, 1.625rem);
      display: inline-block;
      transform: rotate(-90deg);
    }
    .bottom {
      p {
        margin: 0;
      }
    }
    .couponCodeText {
      font-weight: 700;
      font-size: clamp(1rem, 0.9393rem + 0.2589vw, 1.25rem);
      margin: clamp(0.375rem, 0.284rem + 0.3883vw, 0.75rem) 0 !important;
    }
    .couponCode {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.light};
      font-weight: 800;
      padding: 0 0.625rem;
    }
    .couponBottomSvg {
      margin: 0 auto;
    }
  `

  return (
    <CouponContainer>
      <StaticImage
        className="couponLogo"
        src="../images/sidebar-logo.png"
        alt="logo"
      />
      <div className="svgCouponContainer">
        <div
          className={
            props.isHomePage === true
              ? `svgCouponTopContainer`
              : `svgCouponTopContainer gray`
          }
        >
          <SvgCouponTop preserveAspectRatio="none" />
        </div>
        <div className="couponText top">
          <div dangerouslySetInnerHTML={{ __html: props.data.content }} />
        </div>
      </div>

      <div className="svgCouponContainer">
        <SvgCouponBottom
          className="couponBottomSvg"
          preserveAspectRatio="none"
        />
        <div className="couponText bottom">
          <div className="discount">
            {props.data.percentageOrFixedAmount === "Fixed Amount" && (
              <span>$</span>
            )}
            <span className="amount">{props.data.discount}</span>
            {props.data.percentageOrFixedAmount === "Percentage" && (
              <span>%</span>
            )}{" "}
            <span className="off">Off</span>
          </div>
          <p className="couponCodeText">{props.data.couponCodeText}</p>
          <p className="couponCode">{props.data.couponCode}</p>
        </div>
      </div>
    </CouponContainer>
  )
}
export { Coupon }
