import * as React from "react"

const SvgCalloutRedShape = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={538.667}
    height={375.533}
    viewBox="0 0 538.667 375.533"
    {...props}
  >
    <path
      data-name="Path 21"
      d="m1123.467 1581 156.8 375.533H741.6V1581Z"
      transform="translate(-741.6 -1581)"
      fill="#e72222"
      style={{
        mixBlendMode: "multiply",
        isolation: "isolate",
      }}
    />
    <path
      data-name="Path 172"
      d="m211.008 0 152.059 375.453H137.323L0 58.6V0Z"
      fill="#ce1213"
    />
  </svg>
)

export default SvgCalloutRedShape
