import React, { Suspense, lazy } from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled/macro"
import { Layout } from "../components/Layout"
import { Seo } from "../components/Seo"
import {
  useSeoData,
  PageSpecificSchemaFunction,
  usePageData,
  useSidebarMenuTitle,
  useMainMenuItems,
  useHomeCouponsData,
  useWhyChooseData,
  useCertificationsData,
  useContactData,
  useServiceAreasData,
  useButtonTextData,
  useTestimonialsData,
} from "../components/DataImports"
import { PageSpecificSchema } from "@ashleynexvelsolutions/page-specific-schema"
import { Container } from "../components/Container"
import { PageContent } from "../components/PageContent"
import { SidebarMenu } from "../components/SidebarMenu"
import { InteriorCoupons } from "../components/InteriorCoupons"
import { device, theme } from "../components/Theme"
import { InteriorVideo } from "../components/InteriorVideo"

const WhyChoose = lazy(() =>
  import("../components/WhyChoose").then(module => ({
    default: module.WhyChoose,
  }))
)
const Certifications = lazy(() =>
  import("../components/Certifications").then(module => ({
    default: module.Certifications,
  }))
)

const Page = ({ data }) => {
  const SidebarContainer = styled.div`
    overflow: hidden;
    @media ${device.lg} {
      grid-row: 1;
      grid-column: 1;
    }
  `
  const ContainerStyled = styled.div`
    margin-top: 2rem;

    @media ${device.lg} {
      margin-top: 9rem;
      margin-bottom: 9rem;
    }
  `
  const PageLayout = styled.div`
    display: grid;

    @media ${device.lg} {
      grid-template-columns: 27% 65%;
      justify-content: space-between;
    }
  `
  const WhiteContainer = styled.div`
    background-color: ${theme.colors.light};
    display: none;
    @media ${device.lg} {
      display: block;
    }
  `

  const CouponContainer = styled.div`
    display: none;
    @media ${device.lg} {
      display: block;
    }
  `

  const pageData = usePageData(data)
  const sidebarMenuTitle = useSidebarMenuTitle()
  const mainMenuItems = useMainMenuItems()
  const couponData = useHomeCouponsData()
  const whyChooseData = useWhyChooseData()
  const certificationsData = useCertificationsData()
  const contactData = useContactData()
  const serviceAreasData = useServiceAreasData()
  const buttonText = useButtonTextData()
  const testimonialData = useTestimonialsData()

  return (
    <>
      <Seo data={useSeoData(data)} />
      <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      <Layout data={data}>
        <ContainerStyled>
          <Container>
            <PageLayout className="pageTemplate">
              <PageContent
                data={pageData}
                couponData={couponData}
                serviceAreasData={serviceAreasData}
                buttonText={buttonText}
                formData={contactData}
              />
              <SidebarContainer>
                <InteriorVideo data={testimonialData} />
                <CouponContainer>
                  <InteriorCoupons data={couponData} />
                </CouponContainer>

                <SidebarMenu
                  title={sidebarMenuTitle.sidebarMenuTitle}
                  mainMenuItems={mainMenuItems}
                  isSidebar={true}
                />
              </SidebarContainer>
            </PageLayout>
          </Container>
        </ContainerStyled>
        <Suspense fallback="loading">
          <WhyChoose data={whyChooseData} />
        </Suspense>
        <Suspense fallback="loading">
          <Certifications data={certificationsData} />
        </Suspense>
      </Layout>
    </>
  )
}
export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
        canonical
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
        areaServedLandingPageSchema
        serviceSchema {
          serviceNameSchema
          linkToRelevantServicePageSchema
        }
        keywordsSchema {
          keywordSchema
        }
        localAwardsSchema {
          localAwardSchema
        }
        latitudeSchema
        longitudeSchema
        relevantExternalPagesSchema {
          relevantExternalPageSchema
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                formats: [AUTO, WEBP, AVIF]
                placeholder: NONE
                quality: 100
              )
            }
          }
        }
      }
      featuredImageAltText {
        featuredImageAltText
      }
      title
      uri
      interiorSection1 {
        interiorSection1Headings
        interiorSection1Content
      }
      interiorSection2 {
        interiorSection2ImageTextHeading
        interiorSection2ImageText
        interiorSection2Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        interiorSection2ImageAltText
        interiorSection2HeadingColumn1
        interiorSection2ContentColumn1
        interiorSection2HeadingColumn2
        interiorSection2ContentColumn2
      }
      interiorSection3 {
        interiorSection3Heading
        interiorSection3Content
      }
    }
    wp {
      nexvelSchemaMarkup {
        nexvelschema {
          cities {
            city
          }
          services {
            service
            linkToRelevantPageOnYourWebsite
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Page
