import React from 'react'
import styled from "@emotion/styled/macro"
import { MobileAndSidebarMenuItems } from './MobileAndSidebarMenuItems'
import SvgInteriorPageDecorativeElement from '../images/SVGs/InteriorPageDecorativeElement'
import { theme } from './Theme'

const SidebarMenu = (props) =>{
    const SidebarMenuContainer = styled.div`
    border-top: 5px solid ${theme.colors.dark};
    border-bottom: 5px solid ${theme.colors.primary};
    box-shadow: 5px 10px 20px ${theme.colors.gray}, -5px 10px 20px ${theme.colors.gray};

    .sidebarTitle{
        font-size: clamp(1.25rem, 0.9466rem + 1.2945vw, 2.5rem);
        font-weight: 800;
        text-align: center;
    }
    .decorativeElementOuterContainer{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
   
    `
    return(
        <SidebarMenuContainer>
        <p className='sidebarTitle'>
            {props.title}
        </p>
        <div className="decorativeElementOuterContainer">
            <div />
        <div className="decorativeElementContainer">
        <SvgInteriorPageDecorativeElement />
        </div>
        </div>
       
       
        <MobileAndSidebarMenuItems mainMenuItems={props.mainMenuItems} isSidebar={true} />
       
        
        </SidebarMenuContainer>
    )
}
export {SidebarMenu}