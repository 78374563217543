import React from "react"
import styled from "@emotion/styled/macro"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { theme, device } from "./Theme"
import { InteriorCoupons } from "../components/InteriorCoupons"
import SvgInteriorPageDecorativeElement from "../images/SVGs/InteriorPageDecorativeElement"
import SvgCalloutRedShape from "../images/SVGs/CalloutRedShape"
import SvgRightArrow from "../images/SVGs/RightArrow"
import { Coupon } from "./Coupon"
import ServiceAreas from "../components/ServiceAreas"
import Fade from "react-reveal/Fade"
import { Helmet } from "react-helmet"
import { MaintenanceContractForm } from "./maintenance-contract-form"

const PageContent = props => {
  const PageContentContainer = styled.section`
    a {
      color: ${theme.colors.primary};
    }
    .imageSection {
      display: grid;

      @media ${device.lg} {
        padding-bottom: 4.0625em;
        border-bottom: 1px solid ${theme.colors.dark};
      }
    }
    .pageImage {
      grid-area: 1/1;
      z-index: -1;
    }
    .svgTextContainer {
      grid-area: 1/1;
      display: grid;
      position: relative;
    }
    .imageSectionRedMobile {
      grid-area: 1/1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.9;
      background-color: ${theme.colors.primary};

      @media ${device.lg} {
        display: none;
      }
    }
    .imageSectionRedShape {
      display: none;

      @media ${device.lg} {
        grid-area: 1/1;
        display: block;
      }
    }
    .imageSectionTextContainer {
      grid-area: 1/1;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3.125rem;
      color: ${theme.colors.light};

      @media ${device.lg} {
        max-width: 373px;
      }
    }
    .imageSectionHeading {
      font-size: clamp(1.25rem, 0.9618rem + 1.2298vw, 2.4375rem);
      line-height: clamp(1.5rem, 1.2391rem + 1.1133vw, 2.575rem);
      font-weight: 800;
    }
    .imageSectionText {
      p {
        margin: 0.96875rem 0 0 0;
      }
    }
    .columns {
      margin: 2.5em 0;

      @media ${device.lg} {
        margin: 2.5em 0 5.75em 0;
        display: flex;
      }
    }
    .column1 {
      @media ${device.lg} {
        padding-right: 3em;
        border-right: 1px solid ${theme.colors.dark};
      }
    }
    .column2 {
      @media ${device.lg} {
        padding-left: 3em;
      }
    }
    .columnHeading {
      font-size: clamp(1.125rem, 0.943rem + 0.7767vw, 1.875rem);
      font-weight: 800;
    }
    .callout {
      display: grid;
    }
    .calloutImage {
      display: none;

      @media ${device.lg} {
        grid-area: 1/1;
        display: block;
      }
    }
    .calloutTextContainer {
      text-align: center;
      grid-area: 1/1;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3.125rem;
      color: ${theme.colors.light};
      font-size: clamp(2.1875rem, 1.8993rem + 1.2298vw, 3.375rem);
      font-weight: 700;
      background-color: ${theme.colors.primary};

      @media ${device.lg} {
        text-align: left;
        max-width: 662px;
        box-sizing: border-box;
        background-color: transparent;
        padding: 2.5625rem 3.125rem;
      }
      .larger {
        font-size: clamp(2rem, 2rem + 1vw, 3rem);
        font-weight: 800;

        @media ${device.lg} {
          font-size: clamp(5.4375rem, 5.1493rem + 1.2298vw, 6.625rem);
        }
      }
      .link {
        background-color: ${theme.colors.dark};
        font-family: ${theme.fonts.altFont};
        text-transform: uppercase;
        padding: 1rem 2rem;
        display: inline-flex;
        align-items: center;
        gap: 2.4375rem;
        color: ${theme.colors.light};
        font-size: 1rem;
        letter-spacing: 1px;
        margin-top: 2.1875rem;
        transition: all 0.25s;

        &:hover {
          background-color: ${theme.colors.secondary};
          color: ${theme.colors.dark};
          & path {
            fill: ${theme.colors.dark};
          }
        }
        @media ${device.lg} {
          padding: 1.625rem 3.4375rem;
        }
      }
    }
    .rightArrow {
      display: none;

      @media ${device.md} {
        display: block;
      }
    }
    .section3Heading {
      font-size: clamp(2.0625rem, 1.8805rem + 0.7767vw, 2.8125rem);
      font-weight: 800;
      line-height: 1;

      @media ${device.lg} {
        margin: 7.0625rem 0 4rem 0 !important;
      }
    }
    .couponContainer {
      display: grid;
      grid-row-gap: 5rem;
      margin: 5rem 0;
      box-sizing: border-box;

      @media ${device.lg} {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
      }
    }
    .background-desktop,
    .background-mobile {
      display: none !important;
    }
    .mobileCoupons {
      width: 89vw;
      display: block;
      overflow: hidden;
      @media ${device.lg} {
      display: none;
     }
    }
  `

  return (
    <PageContentContainer>
      {props.data.interiorSection1.headings !== null && (
        <>
          <Fade bottom cascade>
            <div
              dangerouslySetInnerHTML={{
                __html: props.data.interiorSection1.headings,
              }}
            />
            <SvgInteriorPageDecorativeElement />
          </Fade>
        </>
      )}
      {props.data.uri !== "/offers/" && (
      <div className="mobileCoupons">
        <InteriorCoupons data={props.couponData} />
      </div>
      )}
      {props.data.interiorSection1.content !== null && (
        <Fade bottom fraction={0.01}>
          <div
            dangerouslySetInnerHTML={{
              __html: props.data.interiorSection1.content,
            }}
          />
        </Fade>
      )}
      {props.data.pageTitle === "Maintenance Contract" && (
        <MaintenanceContractForm />
      )}
      {props.data.pageTitle === "Reviews" && (
        <>
          <Helmet>
            <script
              id="reviewScript"
              src={process.env.GATSBY_BIRDEYE_REVIEW_URL}
            />
          </Helmet>
          <div id={process.env.GATSBY_BIRDEYE_REVIEW_ID} />
        </>
      )}
      {props.data.uri === "/offers/" && (
        <div className="couponContainer">
          {props.couponData.coupons.map(coupon => {
            return <Coupon data={coupon} isHomePage={false} />
          })}
        </div>
      )}
      {props.data.interiorSection2.imageSectionHeading !== null && (
        <div className="imageSection">
          <GatsbyImage
            className="pageImage"
            image={props.data.interiorSection2.image}
            alt={props.data.interiorSection2.altText}
          />
          <div className="svgTextContainer">
            <div className="imageSectionRedMobile" />
            <SvgCalloutRedShape
              preserveAspectRatio="xMidYMin meet"
              className="imageSectionRedShape"
            />
            <div className="imageSectionTextContainer">
              <div
                className="imageSectionHeading"
                dangerouslySetInnerHTML={{
                  __html: props.data.interiorSection2.imageSectionHeading,
                }}
              />
              <div
                className="imageSectionText"
                dangerouslySetInnerHTML={{
                  __html: props.data.interiorSection2.imageSectionText,
                }}
              />
            </div>
          </div>
        </div>
      )}
      {props.data.interiorSection2.column1Heading !== null && (
        <div className="columns">
          <div className="column1">
            <div
              className="columnHeading"
              dangerouslySetInnerHTML={{
                __html: props.data.interiorSection2.column1Heading,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: props.data.interiorSection2.column1Content,
              }}
            />
          </div>
          <div className="column2">
            <div
              className="columnHeading"
              dangerouslySetInnerHTML={{
                __html: props.data.interiorSection2.column2Heading,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: props.data.interiorSection2.column2Content,
              }}
            />
          </div>
        </div>
      )}
      {props.data.pageTitle === "Service Areas" && (
        <ServiceAreas
          data={props.serviceAreasData}
          buttonText={props.buttonText}
          formData={props.formData}
        />
      )}
      <div className="callout">
        <GatsbyImage
          className="calloutImage"
          image={props.data.callout.image}
          alt={props.data.callout.altText}
        />
        <div className="calloutTextContainer">
          <div dangerouslySetInnerHTML={{ __html: props.data.callout.text }} />
          <div>
            <Link className="link" to={props.data.callout.link}>
              <span>{props.data.callout.linkText}</span>
              <SvgRightArrow className="rightArrow" />
            </Link>
          </div>
        </div>
      </div>
      {props.data.interiorSection3.content !== null && (
        <>
          {props.data.interiorSection3.heading !== null && (
            <Fade bottom>
              <p className="section3Heading">
                {props.data.interiorSection3.heading}
              </p>
            </Fade>
          )}
          <Fade bottom fraction={0.01}>
            <div
              dangerouslySetInnerHTML={{
                __html: props.data.interiorSection3.content,
              }}
            />
          </Fade>
        </>
      )}
    </PageContentContainer>
  )
}
export { PageContent }
