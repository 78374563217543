import React from "react"
import styled from "@emotion/styled/macro"

const ContainerDiv = styled.div`
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: 576px) {
    padding: 0 1.25rem;
  }

  @media (min-width: 400px) {
    max-width: 90.75rem;
  }
`

const Container = ({ children }) => {
  return <ContainerDiv>{children}</ContainerDiv>
}

export { Container }
