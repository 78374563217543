import React from "react"

import { CouponsContainer, CouponList, CouponListInner } from "./index.style"

import CouponCard from "../CouponCard"
import { Coupon } from "../Coupon"

const InteriorCoupons = ({ data }) => {
  return (
    <CouponsContainer>
      <CouponList>
        <CouponListInner>
          {(data.coupons || []).map((coupon, key) => (
            <Coupon key={key} data={coupon} />
          ))}
        </CouponListInner>
      </CouponList>
    </CouponsContainer>
  )
}

export { InteriorCoupons }
