import styled from "@emotion/styled/macro"
import { theme, device } from "../Theme"
import { Container } from "../../styles/Common"

const CouponsContainer = styled.section`
  position: relative;
`

const CouponList = styled.div`
  padding: 0 24px;
`

const CouponListInner = styled.div`
  margin: 0 -30px;
  padding: 20px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  & > * {
    margin-left: 20px;
    margin-right: 20px;
    @media ${device.lg} {
      max-width: 80%;
    }
  }
`

export { CouponsContainer, CouponList, CouponListInner }
